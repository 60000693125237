import { FunctionComponent, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ArrowLeft, PencilRuler } from 'lucide-react'
import { useRouter } from 'next/router'
import { useLocalStorage } from 'react-use'

import { Unit } from 'data-access'
import { SupportedLocale } from 'data-access/domain/constants'
import { Button } from '@ui/components/ui/button'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@ui/components/ui/sheet'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@ui/components/ui/tabs'
import { cn } from '@ui/lib/utils'

import MisterImage from 'src/components/shared/image/MisterImage'
import RichText from 'src/components/shared/rich-text/rich-text'
import { useTranslations } from 'src/contexts/Globals.context'
import { getSizeGuide } from 'src/domain/sizeGuide.domain'
import MisterSizeChart from 'src/features/size-guide/MisterSizeChart'
import ConversionTable from './ConversionTable'
import MisterMeasurementsGuide from './MisterMeasurementsGuide'
import MisterSizeGuideSkeleton from './MisterSizeGuideSkeleton'

interface MisterSizeGuideContentProps {
  collectionId: string
  howToMeasureAsDrawer?: boolean
}

const MisterSizeGuideContent: FunctionComponent<MisterSizeGuideContentProps> = ({ collectionId, howToMeasureAsDrawer = false }) => {
  const { locale } = useRouter()
  const translate = useTranslations()

  const [tabValue, setTabValue] = useState('Size Charts')

  useEffect(() => {
    setTabValue('Size Charts')
  }, [collectionId])

  const [savedUnit, saveUnit] = useLocalStorage<Unit>('sizeUnit')
  const [currentUnit, setCurrentUnit] = useState<Unit>(savedUnit ? savedUnit : locale == 'gb' ? 'in' : 'cm')

  const changeUnit = (unit: Unit) => {
    saveUnit(unit)
    setCurrentUnit(unit)
  }

  const { data, error, isLoading } = useQuery({
    queryKey: [collectionId],
    queryFn: () => getSizeGuide(collectionId, locale as SupportedLocale),
    enabled: !!locale,
  })

  if (isLoading) return <MisterSizeGuideSkeleton />

  if (error) return <div>Error</div>

  if (!data) return null

  return (
    <>
      <div className='[container-type:inline-size]'>
        <div className={cn('mb-10 grid overflow-hidden rounded-md', data?.collectionImage && '@md:grid-cols-2')}>
          {data?.collectionImage && <MisterImage className='hidden size-full object-cover @md:block' mobile={data.collectionImage} />}
          <div className='bg-brand-beige px-4 py-5'>
            <h3 className='pb-3 text-body-lg-bold'>{data.title}</h3>
            <RichText className='text-body-md' data={data?.sizingDescription} />
          </div>
        </div>
      </div>
      <Tabs value={tabValue} onValueChange={(value) => setTabValue(value)}>
        {!!data.conversionTables.length && (
          <TabsList className='mb-5 inline-flex w-full min-w-24 overflow-x-scroll scrollbar-hide'>
            <TabsTrigger value='Size Charts'>{translate('sizeChart', 'Size Chart')}</TabsTrigger>
            <TabsTrigger value='Size Conversions'>{translate('sizeConversions', 'Size Conversions')}</TabsTrigger>
          </TabsList>
        )}

        <TabsContent value='Size Charts'>
          <div className='mb-5 flex flex-row-reverse items-center justify-between'>
            <div className='flex rounded-md bg-brand-blue-pale p-1 text-body-sm-bold'>
              <button
                className={cn('w-10 rounded-sm px-2.5 py-1.5 text-btn-sm uppercase', 'cm' === currentUnit ? 'bg-white font-bold opacity-100' : 'opacity-50')}
                onClick={() => changeUnit('cm')}
              >
                cm
              </button>

              <button
                className={cn('w-10 rounded-sm px-2.5 py-1.5 text-btn-sm uppercase', 'in' === currentUnit ? 'bg-white font-bold opacity-100' : 'opacity-50')}
                onClick={() => changeUnit('in')}
              >
                in
              </button>
            </div>
            {howToMeasureAsDrawer && (
              <Sheet>
                <SheetTrigger asChild className='flex items-center gap-2'>
                  <div>
                    <PencilRuler size={20} />
                    <Button variant='link' hover='underline'>
                      <span className='text-body-md-bold'>{translate('howToMeasure', 'How to measure')}</span>
                    </Button>
                  </div>
                </SheetTrigger>

                <SheetContent className='overflow-y-scroll'>
                  <SheetHeader className='mb-5'>
                    <SheetTitle>{translate('howToMeasure', 'How to measure')}</SheetTitle>
                    <SheetDescription className='sr-only'>{translate('howToMeasure', 'How to measure')}</SheetDescription>
                  </SheetHeader>
                  <SheetTrigger asChild className='mb-5 flex items-center gap-2'>
                    <div>
                      <ArrowLeft size={20} />
                      <Button variant='link' hover='underline'>
                        <span className='text-body-md-bold'>{translate('backToSizeCharts', 'Back to Size Charts')}</span>
                      </Button>
                    </div>
                  </SheetTrigger>

                  <MisterMeasurementsGuide howToMeasureAsDrawer measurements={data.measurements} />
                </SheetContent>
              </Sheet>
            )}
          </div>

          <div className='mb-10 flex flex-col gap-7'>
            {data.sizeCharts?.map((sizeChart, i) => <MisterSizeChart key={i} name={data.title} sizeChart={sizeChart} unit={currentUnit} />)}
          </div>
          <span className='text-body-md'>
            {translate('sizeChartNote', 'Please note that our products are handmade and may vary slightly in dimensions from the measurements listed above.')}
          </span>

          {!howToMeasureAsDrawer && <MisterMeasurementsGuide className='mt-20' measurements={data.measurements} />}
        </TabsContent>

        <TabsContent value='Size Conversions'>
          {data.conversionTables.map((conversionTable, i) => (
            <ConversionTable key={i} conversionTable={conversionTable} />
          ))}
        </TabsContent>
      </Tabs>
    </>
  )
}

export default MisterSizeGuideContent
